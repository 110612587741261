
export default {
  props: {
    components: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getComponentName(component) {
      return component?.type?.replace(/_/g, '-') ?? 'div'
    },
  },
}
